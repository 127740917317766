import { PDDData1, PDDData2 } from "./PDDData";

import { Accordion } from "../components/Accordion";
import { IoMdInformationCircle } from "react-icons/io";
import { MdMore } from "react-icons/md";
import PDDtermsAndConditions from "../download/Regulamin_PDD.pdf";
import React from "react";
import { TbDiscountCheckFilled } from "react-icons/tb";
import classes from "./PDD.module.css";

// import PDDform from "../download/Formularz.pdf";

function PDD() {
  return (
    <div className={classes.pdd__wrapper}>
      {/* <h1 className={classes.heading__main}>PDD</h1> */}
      <h2 className={classes.heading__secondary}>Program dla doktorantów</h2>
      <hr className={classes.pdd__divider} />
      <p className={classes.pdd__towho}>
        Jest to Program skierowany do grup badawczych, skupiających w swoim gronie Naukowców w tym: Profesorów,
        Doktorów, Doktorantów, Asystentów współpracujących z Firmą Symbios.
      </p>
      <p className={classes.pdd__whatfor}>
          Dzięki niemu uzyskują Państwo bonus w wysokości do 10% wartości netto dokonanych zakupów, który może zostać wykorzystany jako :
      </p>
      <ul className={classes.unordered__list}>
        <li>
          <div className={classes.li__wrapper}>
          <span className={classes.list__number}><span className={classes.number__marker}></span></span>
            Dofinansowanie wyjazdu naukowego uczestników programu
          </div>
        </li>
        <li>
          <div className={classes.li__wrapper}>
          <span className={classes.list__number}><span className={classes.number__marker}></span></span>Otrzymanie produktów z oferty Symbios w kwocie odpowiadającej
            wysokości uzbieranego bonusu
          </div>
        </li>
        <li>
          <div className={classes.li__wrapper}>
          <span className={classes.list__number}><span className={classes.number__marker}></span></span>
            Rabat przy zakupie sprzętu lub na inne wydatki placówki związane z działalnością naukową
          </div>
        </li>
      </ul>

      <div>
        <div className={classes.icons__wrapper}>
          <div className={classes.icon__wrapper}>
            <div className={classes.icons__text}>
              <TbDiscountCheckFilled />
              <h2 className={classes.heading__icon}>Rabaty</h2>
            </div>
            <p className={classes.expl__text}>
              Rabaty naliczane wyłącznie dla Partnerów Programu Symbios, pozwalające uzyskać specjalne warunki zakupowe
            </p>
          </div>

          <div className={classes.icon__wrapper}>
            <div className={classes.icons__text}>
              <IoMdInformationCircle />
              <h2 className={classes.heading__icon}>Wgląd do subkonta</h2>
            </div>
            <p className={classes.expl__text}>
              Wgląd do subkonta z naliczaną kwotą przez cały czas trwania programu, pozwalający na planowanie sposobu
              ich wykorzystania przed końcem roku kalendarzowego
            </p>
          </div>
          <div className={classes.icon__wrapper}>
            <div className={classes.icons__text}>
              <MdMore />
              <h2 className={classes.heading__icon}>Dofinansowania</h2>
            </div>
            <p className={classes.expl__text}>Dofinansowanie działalności naukowej placówki i jej pracowników</p>
          </div>
        </div>
        <div className={classes.expl__wrapper}></div>
      </div>
      <h2 className={classes.heading__secondary}>Często zadawane pytania:</h2>
      <p className={classes.accordion__explanation}>Tu znajdziesz odpowiedzi na większość zadawanych nam pytań</p>
      <hr className={classes["pdd__divider--padding"]} />
      <div>
        <div>
          {PDDData1.map((section, index) => (
            <Accordion key={index} section={section} />
          ))}
        </div>
        <div>
          {PDDData2.map((section, index) => (
            <Accordion key={index} section={section} />
          ))}
        </div>
      </div>
        <div className={classes.buttons__width}>
            <h2 className={classes.heading__secondary}>Dowiedz się więcej!</h2>
            <hr className={classes["pdd__divider--padding"]}/>
            <div className={classes.buttons__wrapper}>
                <a className={classes.PDD__buttons} href={PDDtermsAndConditions} target="_blank" rel="noreferrer">
                    Regulamin
                </a>

            </div>
           {/* <div className={classes.buttons__wrapper}>

                <a
                    className={classes.PDD__buttons__join}
                    href="/przystapienie_do_pdd"
                    target="_self"
                    rel="noreferrer"
                >
                    Formularz Przystapienia do PDD
                </a>
            </div>*/}
        </div>
    </div>
  );
}

export default PDD;
