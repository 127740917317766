import abcamLogo from "../components/assets/images/abcam.webp";
import abtLogo from "../components/assets/images/ABT_logo.png";
import amLogo from "../components/assets/images/activemotif.webp";
import antibodiesComLogo from "../components/assets/images/antibodies.comLogo.png";
import candorLogo from "../components/assets/images/logo-candor.svg";
import cyanagenLogo from "../components/assets/images/cyanagen.webp";
import everestLogo from "../components/assets/images/everest.png";
import finetestLogo from "../components/assets/images/fntest.webp";
import genedirexLogo from "../components/assets/images/genedirex.webp";
import ictLogo from "../components/assets/images/ict.webp";
import invitekLogo from "../components/assets/images/invitek-1.webp";
import molnovaLogo from "../components/assets/images/molnova.png";
import primerdesignLogo from "../components/assets/images/primer-logo.png";
import starlabLogo from "../components/assets/images/starlab.webp";
import targetmolLogo from "../components/assets/images/targetmol.webp";
import unitmaLogo from "../components/assets/images/unitma.png";
import uvpLogo from "../components/assets/images/uvp.webp";

// import lucigenLogo from "../components/assets/images/Lucigen-min.webp";

const groups = [
    {
        group: "Przeciwciala",
        companies: [
            {
                id: 1,
                href: "https://www.abcam.com/",
                company: "Abcam",
                "text": "<b>Symbios jest jedynym autoryzowanym dystrybutorem produktów firmy Abcam w Polsce.</b><br><br> Abcam to światowej klasy producent oferujący przeciwciała pierwszo- i drugorzędowe, odczynniki do obrazowania mikroskopowego, peptydy oraz szeroki wybór testów biochemicznych i immunochemicznych. W ofercie firmy znajdują się również innowacyjne zestawy SimpleStep ELISA®, umożliwiające uzyskanie wyników w mniej niż 90 minut.",
                logo: abcamLogo,
                alt: "Logo Abcam",
            },
            {
                id: 4,
                href: "https://www.antibodies.com/",
                company: "Antibodies.com",
                "text": "Antibodies.com z główną siedzibą w Cambridge (UK) i biurami w Sztokholmie (SE) oraz i St Louis (MO, USA) zapewnia naukowcom zajmującym się naukami przyrodniczymi wysokiej jakości odczynniki biologiczne, pochodzące tych sam źródeł głównych producentów ale w bardziej przystępnych cenach. W asortymencie znajdują się min. przeciwciała, testy ELISA, barwniki wykorzystywane w mikroskopii fluorescencyjnej.",
                logo: antibodiesComLogo,
                alt: "Logo Antibodies.com",
            },
            {
                id: 3,
                href: "https://www.activemotif.com/",
                company: "Active Motif",
                "text": "Produkty do badań epigenetycznych i regulacji ekspresji genów, w tym do analizy metylacji DNA i badania modyfikacji histonowych, zestawy ChIP, przeciwciała (min. AbFlex), barwniki Chromeo oraz zestawy do oceny aktywacji czynników transkrypcyjnych. W asortymencie producenta znajduje się zestaw CUT&Tag-IT™ pozwalający na badanie lokalizacji genomowej modyfikacji histonowych w puli 5000 komórek",
                logo: amLogo,
                alt: "Logo Active Motif",
            },
            {
                id: 8,
                href: "https://www.fn-test.com/",
                company: "Fine-Test",
                "text": "Ponad 1200 testów ELISA, przeciwciał pierwszorzędowe, białka rekombinowane oraz linie komórkowe. Produkty cytowane w wielu prestiżowych czasopismach (m. in. Cell, Hepatology, ACS Nano, Nanoscale) ",
                logo: finetestLogo,
                alt: "Logo Fine-Test",
            },
            {
                id: 7,
                href: "https://everestbiotech.com/",
                company: "Everest Biotech",
                "text": "Poliklonalne przeciwciała kozie, w tym ponad 1000 przeciwciał przeciwko antygenom skorelowanym z jednostkami chorobowymi oraz 3000 pozwalających na badanie białek różnych organelli i procesów wewnątrzkomórkowych. Przeciwciała typu Elite Grade, Aspiring Grade oraz możliwość produkcji na zamówienie.",
                logo: everestLogo,
                alt: "Logo Everest Biotech",
            },
        ],
    },
    {
        group: "Zestawy_do_Elisa",
        companies: [
            {
                id: 1,
                href: "https://www.abcam.com/",
                company: "Abcam",
                "text": "<b>Symbios jest jedynym autoryzowanym dystrybutorem produktów firmy Abcam w Polsce.</b><br><br> Abcam to światowej klasy producent oferujący przeciwciała pierwszo- i drugorzędowe, odczynniki do obrazowania mikroskopowego, peptydy oraz szeroki wybór testów biochemicznych i immunochemicznych. W ofercie firmy znajdują się również innowacyjne zestawy SimpleStep ELISA®, umożliwiające uzyskanie wyników w mniej niż 90 minut.",
                logo: abcamLogo,
                alt: "Logo Abcam",
            },
            {
                id: 3,
                href: "https://www.activemotif.com/",
                company: "Active Motif",
                "text": "Produkty do badań epigenetycznych i regulacji ekspresji genów, w tym do analizy metylacji DNA i badania modyfikacji histonowych, zestawy ChIP, przeciwciała (min. AbFlex), barwniki Chromeo oraz zestawy do oceny aktywacji czynników transkrypcyjnych. W asortymencie producenta znajduje się zestaw CUT&Tag-IT™ pozwalający na badanie lokalizacji genomowej modyfikacji histonowych w puli 5000 komórek",
                logo: amLogo,
                alt: "Logo Active Motif",
            },
            {
                id: 8,
                href: "https://www.fn-test.com/",
                company: "Fine-Test",
                "text": "Ponad 1200 testów ELISA, przeciwciał pierwszorzędowe, białka rekombinowane oraz linie komórkowe. Produkty cytowane w wielu prestiżowych czasopismach (m. in. Cell, Hepatology, ACS Nano, Nanoscale) ",
                logo: finetestLogo,
                alt: "Logo Fine-Test",
            },
            {
                id: 10,
                href: "https://www.immunochemistry.com/",
                company: "ICT",
                "text": "Testy do badania mechanizmów śmierci komórki FLICA® występujące w wielu wariantach (badające aktywność kaspazy 3/7, 8, 9, również w opcji poli-caspase) lub in vivo w formie linii produktów FLIVO®. W ofercie producenta występują także reagenty FAM-DEVD-OPH, w którym zastosowano znacznie mniej toksyczną grupę O-phenoxy (OPH) zamiast fluorometyloketonowej – FMK (FLICA) oraz zestawy do badania stresu oksydacyjnego MitoPT.",
                logo: ictLogo,
                alt: "Logo ICT",
            },
        ],
    },
    {
        group: "Bialka_i_peptydy",
        companies: [
            {
                id: 1,
                href: "https://www.abcam.com/",
                company: "Abcam",
                "text": "<b>Symbios jest jedynym autoryzowanym dystrybutorem produktów firmy Abcam w Polsce.</b><br><br> Abcam to światowej klasy producent oferujący przeciwciała pierwszo- i drugorzędowe, odczynniki do obrazowania mikroskopowego, peptydy oraz szeroki wybór testów biochemicznych i immunochemicznych. W ofercie firmy znajdują się również innowacyjne zestawy SimpleStep ELISA®, umożliwiające uzyskanie wyników w mniej niż 90 minut.",
                logo: abcamLogo,
                alt: "Logo Abcam",
            },
            {
                id: 3,
                href: "https://www.activemotif.com/",
                company: "Active Motif",
                "text": "Produkty do badań epigenetycznych i regulacji ekspresji genów, w tym do analizy metylacji DNA i badania modyfikacji histonowych, zestawy ChIP, przeciwciała (min. AbFlex), barwniki Chromeo oraz zestawy do oceny aktywacji czynników transkrypcyjnych. W asortymencie producenta znajduje się zestaw CUT&Tag-IT™ pozwalający na badanie lokalizacji genomowej modyfikacji histonowych w puli 5000 komórek",
                logo: amLogo,
                alt: "Logo Active Motif",
            },
            {
                id: 8,
                href: "https://www.fn-test.com/",
                company: "Fine-Test",
                "text": "Ponad 1200 testów ELISA, przeciwciał pierwszorzędowe, białka rekombinowane oraz linie komórkowe. Produkty cytowane w wielu prestiżowych czasopismach (m. in. Cell, Hepatology, ACS Nano, Nanoscale) ",
                logo: finetestLogo,
                alt: "Logo Fine-Test",
            },
            {
                id: 10,
                href: "https://www.immunochemistry.com/",
                company: "ICT",
                "text": "Testy do badania mechanizmów śmierci komórki FLICA® występujące w wielu wariantach (badające aktywność kaspazy 3/7, 8, 9, również w opcji poli-caspase) lub in vivo w formie linii produktów FLIVO®. W ofercie producenta występują także reagenty FAM-DEVD-OPH, w którym zastosowano znacznie mniej toksyczną grupę O-phenoxy (OPH) zamiast fluorometyloketonowej – FMK (FLICA) oraz zestawy do badania stresu oksydacyjnego MitoPT.",
                logo: ictLogo,
                alt: "Logo ICT",
            },
        ],
    },
    {
        group: "Zwiazki_chemiczne",
        companies: [
            {
                id: 1,
                href: "https://www.abcam.com/",
                company: "Abcam",
                "text": "<b>Symbios jest jedynym autoryzowanym dystrybutorem produktów firmy Abcam w Polsce.</b><br><br> Abcam to światowej klasy producent oferujący przeciwciała pierwszo- i drugorzędowe, odczynniki do obrazowania mikroskopowego, peptydy oraz szeroki wybór testów biochemicznych i immunochemicznych. W ofercie firmy znajdują się również innowacyjne zestawy SimpleStep ELISA®, umożliwiające uzyskanie wyników w mniej niż 90 minut.",
                logo: abcamLogo,
                alt: "Logo Abcam",
            },
            {
                id: 17,
                href: "https://www.targetmol.com/",
                company: "Targetmol",
                "text": "Oferuje ponad 170 rodzajów bibliotek związków oraz szeroką gamę wysokiej jakości chemikaliów badawczych o bardzo wysokiej czystości (powyżej 98%), w tym inhibitory, aktywatory, produkty naturalne, peptydy, przeciwciała i nowatorskie zestawy do nauk przyrodniczych do użytku laboratoryjnego i naukowego.",
                logo: targetmolLogo,
                alt: "Logo Targetmol",
            },
            {
                id: 14,
                href: "https://www.molnova.com/",
                company: "Molnova",
                "text": "Producent oferuje szeroką gamę wysokiej jakości chemikaliów badawczych, w tym nowatorskie odczynniki do nauk przyrodniczych, inhibitory, peptydy, aktywatory, API i naturalne związki do użytku naukowego. Molnova świadczy również najnowocześniejsze usługi niestandardowe, w tym syntezę chemiczną, syntezę peptydów, badania przesiewowe leków.",
                logo: molnovaLogo,
                alt: "Logo Molnova ",
            },
        ],
    },
    {
        group: "Linie_komorkowe_i_lizaty",
        companies: [
            {
                id: 1,
                href: "https://www.abcam.com/",
                company: "Abcam",
                "text": "<b>Symbios jest jedynym autoryzowanym dystrybutorem produktów firmy Abcam w Polsce.</b><br><br> Abcam to światowej klasy producent oferujący przeciwciała pierwszo- i drugorzędowe, odczynniki do obrazowania mikroskopowego, peptydy oraz szeroki wybór testów biochemicznych i immunochemicznych. W ofercie firmy znajdują się również innowacyjne zestawy SimpleStep ELISA®, umożliwiające uzyskanie wyników w mniej niż 90 minut.",
                logo: abcamLogo,
                alt: "Logo Abcam",
            },
        ],
    },
    {
        group: "Izolacja_DNA_RNA",
        companies: [
            {
                id: 12,
                href: "https://www.invitek.com/",
                company: "Invitek",
                "text": "Zestawy i sprzęt do manualnej oraz zautomatyzowanej izolacji kwasów nukleinowych, opatentowana technologia oparta o sole niechaotropowe niedenaturujące i niedegradujące makromolekuł (białek, dużych cząsteczek DNA), zestawy z certyfikatem IVD, również do badania mikrobiomu.",
                logo: invitekLogo,
                alt: "Logo Invitek",
            },
            {
                id: 3,
                href: "https://www.activemotif.com/",
                company: "Active Motif",
                "text": "Produkty do badań epigenetycznych i regulacji ekspresji genów, w tym do analizy metylacji DNA i badania modyfikacji histonowych, zestawy ChIP, przeciwciała (min. AbFlex), barwniki Chromeo oraz zestawy do oceny aktywacji czynników transkrypcyjnych. W asortymencie producenta znajduje się zestaw CUT&Tag-IT™ pozwalający na badanie lokalizacji genomowej modyfikacji histonowych w puli 5000 komórek",
                logo: amLogo,
                alt: "Logo Active Motif",
            },
            // {
            //   id: 13,
            //   href: "https://shop.biosearchtech.com/lucigen",
            //   company: "Lucigen",
            //   text: "Enzymy, mastermixy do PCR, komórki kompetentne, wektory do klonowania oraz nadprodukcji białek. Flagowymi produktami są zestawy do ekstrakcji DNA/RNA w czasie 8 minut QuickExtract™ oraz wektory do nadprodukcji białek w systemie bakteryjnym Expresso®, wykorzystujące rekombinację homologiczną in vivo do klonowania amplifikowanego metodą PCR DNA do wektorów bez konieczności stosowania enzymów, etapów oczyszczania oraz trawienia wektora i wstawki.",
            //   logo: lucigenLogo,
            //   alt: "Logo Lucigen",
            // },
        ],
    },
    {
        group: "PCR_qPCR",
        companies: [
            // {
            //   id: 13,
            //   href: "https://shop.biosearchtech.com/lucigen",
            //   company: "Lucigen",
            //   text: "Enzymy, mastermixy do PCR, komórki kompetentne, wektory do klonowania oraz nadprodukcji białek. Flagowymi produktami są zestawy do ekstrakcji DNA/RNA w czasie 8 minut QuickExtract™ oraz wektory do nadprodukcji białek w systemie bakteryjnym Expresso®, wykorzystujące rekombinację homologiczną in vivo do klonowania amplifikowanego metodą PCR DNA do wektorów bez konieczności stosowania enzymów, etapów oczyszczania oraz trawienia wektora i wstawki.",
            //   logo: lucigenLogo,
            //   alt: "Logo Lucigen",
            // },
            {
                id: 15,
                href: "http://www.primerdesign.co.uk/home",
                company: "Primer Design",
                "text": "Producent oferuje liofilizowane reagenty do Qpcr (linia produktowa oasig), mastermix PrecisionFast zawierający ultra-szybką polimerazę znacznie skracającą czas trwania cykli Qpcr oraz serię PrecisionPlus do Qpcr (polimeraza hot start Taq) i RT-Qpcr. Ponadto w ofercie producenta występują też gotowe zestawy diagnostyczne do wykrywania patogenów ludzkich, zwierzęcych i roślinnych, GMO, zagrożeń biologicznych, analizy żywności oraz mutacji i polimorfizmów.",
                logo: primerdesignLogo,
                alt: "Logo Primer Design ",
            },
        ],
    },
    {
        group: "Zestawy_do_IHC",
        companies: [
            {
                id: 8,
                href: "https://www.fn-test.com/",
                company: "Fine-Test",
                "text": "Ponad 1200 testów ELISA, przeciwciał pierwszorzędowe, białka rekombinowane oraz linie komórkowe. Produkty cytowane w wielu prestiżowych czasopismach (m. in. Cell, Hepatology, ACS Nano, Nanoscale) ",
                logo: finetestLogo,
                alt: "Logo Fine-Test",
            },
        ],
    },
    {
        group: "CHIP",
        companies: [
            {
                id: 3,
                href: "https://www.activemotif.com/",
                company: "Active Motif",
                "text": "Produkty do badań epigenetycznych i regulacji ekspresji genów, w tym do analizy metylacji DNA i badania modyfikacji histonowych, zestawy ChIP, przeciwciała (min. AbFlex), barwniki Chromeo oraz zestawy do oceny aktywacji czynników transkrypcyjnych. W asortymencie producenta znajduje się zestaw CUT&Tag-IT™ pozwalający na badanie lokalizacji genomowej modyfikacji histonowych w puli 5000 komórek",
                logo: amLogo,
                alt: "Logo Active Motif",
            },
        ],
    },
    {
        group: "Regulacja_genow",
        companies: [
            {
                id: 3,
                href: "https://www.activemotif.com/",
                company: "Active Motif",
                text: "Produkty do badań epigenetycznych i regulacji ekspresji genów, w tym do analizy metylacji DNA i badania modyfikacji histonowych, zestawy ChIP, przeciwciała (min. AbFlex), barwniki Chromeo oraz zestawy do oceny aktywacji czynników transkrypcyjnych. W asortymencie producenta znajduje się zestaw CUT&Tag-IT™ pozwalający na badanie lokalizacji genomowej modyfikacji histonowych w puli 5000 komórek",
                logo: amLogo,
                alt: "Logo Active Motif",
            },
        ],
    },
    {
        group: "Analiza_chromatyny",
        companies: [
            {
                id: 3,
                href: "https://www.activemotif.com/",
                company: "Active Motif",
                "text": "Produkty do badań epigenetycznych i regulacji ekspresji genów, w tym do analizy metylacji DNA i badania modyfikacji histonowych, zestawy ChIP, przeciwciała (min. AbFlex), barwniki Chromeo oraz zestawy do oceny aktywacji czynników transkrypcyjnych. W asortymencie producenta znajduje się zestaw CUT&Tag-IT™ pozwalający na badanie lokalizacji genomowej modyfikacji histonowych w puli 5000 komórek",
                logo: amLogo,
                alt: "Logo Active Motif",
            },
        ],
    },
    {
        group: "Metylacja_DNA",
        companies: [
            {
                id: 3,
                href: "https://www.activemotif.com/",
                company: "Active Motif",
                "text": "Produkty do badań epigenetycznych i regulacji ekspresji genów, w tym do analizy metylacji DNA i badania modyfikacji histonowych, zestawy ChIP, przeciwciała (min. AbFlex), barwniki Chromeo oraz zestawy do oceny aktywacji czynników transkrypcyjnych. W asortymencie producenta znajduje się zestaw CUT&Tag-IT™ pozwalający na badanie lokalizacji genomowej modyfikacji histonowych w puli 5000 komórek",
                logo: amLogo,
                alt: "Logo Active Motif",
            },
        ],
    },
    {
        group: "Enzymy",
        companies: [

            {
                id: 9,
                href: "https://www.genedirex.com/",
                company: "GeneDirex",
                "text": "Produkty do badań kwasów nukleinowych (PCR, izolacja DNA/RNA, elektroforeza agarozowa), produkty do hodowli komórkowych komórek ssaczych i owadzich oraz odczynniki do transfekcji. Firma posiada w ofercie transiluminator pBLook z diodą led jak i linię produktów do PCR – OnePCR Supermix, są to produkty typu 3 w 1, gdzie poza standardowymi komponentami dodany został barwnik do śledzenia próbki w żelu agarozowym oraz fluorescencyjny barwnik interkalujący do nici DNA. Produkt dostępny z polimerazą Taq, Hot start, Hi-Fi oraz Plus (o wysokiej procesywności).",
                logo: genedirexLogo,
                alt: "Logo GeneDirex",
            },
            // {
            //   id: 13,
            //   href: "https://shop.biosearchtech.com/lucigen",
            //   company: "Lucigen",
            //   text: "Enzymy, mastermixy do PCR, komórki kompetentne, wektory do klonowania oraz nadprodukcji białek. Flagowymi produktami są zestawy do ekstrakcji DNA/RNA w czasie 8 minut QuickExtract™ oraz wektory do nadprodukcji białek w systemie bakteryjnym Expresso®, wykorzystujące rekombinację homologiczną in vivo do klonowania amplifikowanego metodą PCR DNA do wektorów bez konieczności stosowania enzymów, etapów oczyszczania oraz trawienia wektora i wstawki.",
            //   logo: lucigenLogo,
            //   alt: "Logo Lucigen",
            // },
        ],
    },
    {
        group: "Klonowanie_i_ekspresja_bialek",
        companies: [
            // {
            //   id: 13,
            //   href: "https://shop.biosearchtech.com/lucigen",
            //   company: "Lucigen",
            //   text: "Enzymy, mastermixy do PCR, komórki kompetentne, wektory do klonowania oraz nadprodukcji białek. Flagowymi produktami są zestawy do ekstrakcji DNA/RNA w czasie 8 minut QuickExtract™ oraz wektory do nadprodukcji białek w systemie bakteryjnym Expresso®, wykorzystujące rekombinację homologiczną in vivo do klonowania amplifikowanego metodą PCR DNA do wektorów bez konieczności stosowania enzymów, etapów oczyszczania oraz trawienia wektora i wstawki.",
            //   logo: lucigenLogo,
            //   alt: "Logo Lucigen",
            // },
        ],
    },
    {
        group: "WB",
        companies: [
            {
                id: 1,
                href: "https://www.abcam.com/",
                company: "Abcam",
                text: "Światowej sławy producent m.in. przeciwciał oraz zestawów o bardzo szerokim zastosowaniu, posiadający w ofercie ponad 110 000 produktów. Tak szeroki asortyment gwarantuje, że każdy znajdzie w ofercie produkt odpowiedni do swojego zapotrzebowania",
                logo: abcamLogo,
                alt: "Logo Abcam",
            },
            {
                id: 8,
                href: "https://www.fn-test.com/",
                company: "Fine-Test",
                "text": "Ponad 1200 testów ELISA, przeciwciał pierwszorzędowe, białka rekombinowane oraz linie komórkowe. Produkty cytowane w wielu prestiżowych czasopismach (m. in. Cell, Hepatology, ACS Nano, Nanoscale) ",
                logo: finetestLogo,
                alt: "Logo Fine-Test",
            },
            {
                id: 5,
                href: "https://www.cyanagen.com/",
                company: "Cyanagen",
                "text": "Substraty do chemiluminescencji w szerokim zakresie czułości serii WESTAR, w tym WESTAR Hipernova o największej czułości z produktów dostępnych na rynku, barwniki fluorescencyjne, zestawy do znakowania przeciwciał. Producent odsprzedaje część asortymentu firmom takim jak Cytiva (GE HealthCare) czy Bio-Rad do zastosowania w ich produktach np. Clarity Max – Bio-Rad lub Amersham ECL Select.",
                logo: cyanagenLogo,
                alt: "Logo Cyanagen",
            },
        ],
    },

    {
        group: "Substraty",
        companies: [
            {
                id: 5,
                href: "https://www.cyanagen.com/",
                company: "Cyanagen",
                "text": "Substraty do chemiluminescencji w szerokim zakresie czułości serii WESTAR, w tym WESTAR Hipernova o największej czułości z produktów dostępnych na rynku, barwniki fluorescencyjne, zestawy do znakowania przeciwciał. Producent odsprzedaje część asortymentu firmom takim jak Cytiva (GE HealthCare) czy Bio-Rad do zastosowania w ich produktach np. Clarity Max – Bio-Rad lub Amersham ECL Select.",
                logo: cyanagenLogo,
                alt: "Logo Cyanagen",
            },
        ],
    },
    {
        group: "Barwniki",
        companies: [
            {
                id: 5,
                href: "https://www.cyanagen.com/",
                company: "Cyanagen",
                "text": "Substraty do chemiluminescencji w szerokim zakresie czułości serii WESTAR, w tym WESTAR Hipernova o największej czułości z produktów dostępnych na rynku, barwniki fluorescencyjne, zestawy do znakowania przeciwciał. Producent odsprzedaje część asortymentu firmom takim jak Cytiva (GE HealthCare) czy Bio-Rad do zastosowania w ich produktach np. Clarity Max – Bio-Rad lub Amersham ECL Select.",
                logo: cyanagenLogo,
                alt: "Logo Cyanagen",
            },
        ],
    },
    {
        group: "Obrazowanie",
        companies: [
            {
                id: 5,
                href: "https://www.cyanagen.com/",
                company: "Cyanagen",
                "text": "Substraty do chemiluminescencji w szerokim zakresie czułości serii WESTAR, w tym WESTAR Hipernova o największej czułości z produktów dostępnych na rynku, barwniki fluorescencyjne, zestawy do znakowania przeciwciał. Producent odsprzedaje część asortymentu firmom takim jak Cytiva (GE HealthCare) czy Bio-Rad do zastosowania w ich produktach np. Clarity Max – Bio-Rad lub Amersham ECL Select.",
                logo: cyanagenLogo,
                alt: "Logo Cyanagen",
            },
        ],
    },
    {
        group: "Bufory",
        companies: [
            {
                id: 6,
                href: "https://www.candor-bioscience.de/",
                company: "Candor",
                "text": "Bufory blokujące, płuczące, do zawieszania próbki oraz stabilizatory przeciwciał. Produkty cechują się stałym, niezależnym od partii składem. W asortymencie firmy znajdziemy innowacyjny produkt ReadyTector®, stanowiący rozwiązanie typu all-in-one do skrócenia czasu procedury WB.",
                logo: candorLogo,
                alt: "Logo Candor ",
            },
        ],
    },
    {
        group: "Testy_przezywalności_komorkowej",
        companies: [
            {
                id: 10,
                href: "https://www.immunochemistry.com/",
                company: "ICT",
                "text": "Testy do badania mechanizmów śmierci komórki FLICA® występujące w wielu wariantach (badające aktywność kaspazy 3/7, 8, 9, również w opcji poli-caspase) lub in vivo w formie linii produktów FLIVO®. W ofercie producenta występują także reagenty FAM-DEVD-OPH, w którym zastosowano znacznie mniej toksyczną grupę O-phenoxy (OPH) zamiast fluorometyloketonowej – FMK (FLICA) oraz zestawy do badania stresu oksydacyjnego MitoPT.",
                logo: ictLogo,
                alt: "Logo ICT",
            },
        ],
    },
    {
        group: "Media",
        companies: [
            {
                id: 9,
                href: "https://www.genedirex.com/",
                company: "GeneDirex",
                "text": "Produkty do badań kwasów nukleinowych (PCR, izolacja DNA/RNA, elektroforeza agarozowa), produkty do hodowli komórkowych komórek ssaczych i owadzich oraz odczynniki do transfekcji. Firma posiada w ofercie transiluminator pBLook z diodą led jak i linię produktów do PCR – OnePCR Supermix, są to produkty typu 3 w 1, gdzie poza standardowymi komponentami dodany został barwnik do śledzenia próbki w żelu agarozowym oraz fluorescencyjny barwnik interkalujący do nici DNA. Produkt dostępny z polimerazą Taq, Hot start, Hi-Fi oraz Plus (o wysokiej procesywności).",
                logo: genedirexLogo,
                alt: "Logo GeneDirex",
            },
        ],
    },
    {
        group: "Zloza_i_kolumny",
        companies: [
            {
                id: 2,
                href: "https://abtbeads.com/",
                company: "ABT (Agarose Bead Technologies)",
                "text": "Złoża chromatograficzne (agaroza, dekstran) do samodzielnego upakowania lub gotowe kolumny do zastosowania z urządzeniami MPLC, FPLC, ÄKTA. Firma oferuje również złoża do zastosowania na skalę przemysłową (Rapid Run™). Produkty stanowią odpowiedniki asortymentu firmy Cytiva (GE HealthCare).",
                logo: abtLogo,
                alt: "Logo ABT (Agarose Bead Technologies)",
            },
        ],
    },
    {
        group: "NGS",
        companies: [

            // {
            //   id: 13,
            //   href: "https://shop.biosearchtech.com/lucigen",
            //   company: "Lucigen",
            //   text: "Enzymy, mastermixy do PCR, komórki kompetentne, wektory do klonowania oraz nadprodukcji białek. Flagowymi produktami są zestawy do ekstrakcji DNA/RNA w czasie 8 minut QuickExtract™ oraz wektory do nadprodukcji białek w systemie bakteryjnym Expresso®, wykorzystujące rekombinację homologiczną in vivo do klonowania amplifikowanego metodą PCR DNA do wektorów bez konieczności stosowania enzymów, etapów oczyszczania oraz trawienia wektora i wstawki.",
            //   logo: lucigenLogo,
            //   alt: "Logo Lucigen",
            // },
            {
                id: 3,
                href: "https://www.activemotif.com/",
                company: "Active Motif",
                "text": "Produkty do badań epigenetycznych i regulacji ekspresji genów, w tym do analizy metylacji DNA i badania modyfikacji histonowych, zestawy ChIP, przeciwciała (min. AbFlex), barwniki Chromeo oraz zestawy do oceny aktywacji czynników transkrypcyjnych. W asortymencie producenta znajduje się zestaw CUT&Tag-IT™ pozwalający na badanie lokalizacji genomowej modyfikacji histonowych w puli 5000 komórek",
                logo: amLogo,
                alt: "Logo Active Motif",
            },
        ],
    },
    {
        group: "Sars-CoV-2",
        companies: [
            {
                id: 3,
                href: "https://www.activemotif.com/",
                company: "Active Motif",
                "text": "Produkty do badań epigenetycznych i regulacji ekspresji genów, w tym do analizy metylacji DNA i badania modyfikacji histonowych, zestawy ChIP, przeciwciała (min. AbFlex), barwniki Chromeo oraz zestawy do oceny aktywacji czynników transkrypcyjnych. W asortymencie producenta znajduje się zestaw CUT&Tag-IT™ pozwalający na badanie lokalizacji genomowej modyfikacji histonowych w puli 5000 komórek",
                logo: amLogo,
                alt: "Logo Active Motif",
            },
        ],
    },
    {
        group: "Sprzet",
        companies: [
            {
                id: 16,
                href: "https://www.starlabgroup.com/",
                company: "Starlab",
                "text": "Materiały zużywalne oraz drobny sprzęt laboratoryjny, w tym tipsy serii TipOne® pasujące na pipety takich marek jak Eppendorf®, BRAND®, Capp®, Gilson®, Hamilton®, HTL oraz naczynia hodowlane i inne materiały zużywalne linii produktowej CytoOne®.",
                logo: starlabLogo,
                alt: "Logo Starlab",
            },
            {
                id: 19,
                href: "https://www.uvp.com/",
                company: "UVP",
                "text": "Amerykański wiodący dostawca wysokiej klasy analitycznej technologii pomiarowej, przyrządów i produktów z zakresu biotechnologii i diagnostyki molekularnej W ofercie znajdują się transiluminatory, lampy laboratoryjne, piece hybrydyzacyjne i crosslinkery UV, kabiny PCR, systemy dokumentacji żeli oraz urządzenia do pomiaru natężenia światła UV.",
                logo: uvpLogo,
                alt: "Logo UVP",
            },
            {
                id: 18,
                href: "http://unitma.com/",
                company: "Unitma",
                "text": "Urządzenia serii QuickRay do manualnego i zautomatyzowanego tworzenia macierzy tkankowych z materiału zatopionego w parafinie ",
                logo: unitmaLogo,
                alt: "Logo Unitma ",
            },
            {
                id: 3,
                href: "https://www.activemotif.com/",
                company: "Active Motif",
                "text": "Produkty do badań epigenetycznych i regulacji ekspresji genów, w tym do analizy metylacji DNA i badania modyfikacji histonowych, zestawy ChIP, przeciwciała (min. AbFlex), barwniki Chromeo oraz zestawy do oceny aktywacji czynników transkrypcyjnych. W asortymencie producenta znajduje się zestaw CUT&Tag-IT™ pozwalający na badanie lokalizacji genomowej modyfikacji histonowych w puli 5000 komórek",
                logo: amLogo,
                alt: "Logo Active Motif",
            },
            {
                id: 9,
                href: "https://www.genedirex.com/",
                company: "GeneDirex",
                "text": "Produkty do badań kwasów nukleinowych (PCR, izolacja DNA/RNA, elektroforeza agarozowa), produkty do hodowli komórkowych komórek ssaczych i owadzich oraz odczynniki do transfekcji. Firma posiada w ofercie transiluminator pBLook z diodą led jak i linię produktów do PCR – OnePCR Supermix, są to produkty typu 3 w 1, gdzie poza standardowymi komponentami dodany został barwnik do śledzenia próbki w żelu agarozowym oraz fluorescencyjny barwnik interkalujący do nici DNA. Produkt dostępny z polimerazą Taq, Hot start, Hi-Fi oraz Plus (o wysokiej procesywności).",
                logo: genedirexLogo,
                alt: "Logo GeneDirex",
            },
        ],
    },
    {
        group: "Koncowki_do_pipet",
        companies: [
            {
                id: 16,
                href: "https://www.starlabgroup.com/",
                company: "Starlab",
                text: "Materiały zużywalne oraz drobny sprzęt laboratoryjny, w tym tipsy serii TipOne® pasujące na pipety takich marek jak Eppendorf®, BRAND®, Capp®, Gilson®, Hamilton®, HTL oraz naczynia hodowlane i inne materiały zużywalne linii produktowej CytoOne®.",
                logo: starlabLogo,
                alt: "Logo Starlab",
            },
        ],
    },
    {
        group: "Pipety",
        companies: [
            {
                id: 16,
                href: "https://www.starlabgroup.com/",
                company: "Starlab",
                text: "Materiały zużywalne oraz drobny sprzęt laboratoryjny, w tym tipsy serii TipOne® pasujące na pipety takich marek jak Eppendorf®, BRAND®, Capp®, Gilson®, Hamilton®, HTL oraz naczynia hodowlane i inne materiały zużywalne linii produktowej CytoOne®.",
                logo: starlabLogo,
                alt: "Logo Starlab",
            },
        ],
    },
    {
        group: "Materialy_zuzywalne",
        companies: [
            {
                id: 16,
                href: "https://www.starlabgroup.com/",
                company: "Starlab",
                text: "Materiały zużywalne oraz drobny sprzęt laboratoryjny, w tym tipsy serii TipOne® pasujące na pipety takich marek jak Eppendorf®, BRAND®, Capp®, Gilson®, Hamilton®, HTL oraz naczynia hodowlane i inne materiały zużywalne linii produktowej CytoOne®.",
                logo: starlabLogo,
                alt: "Logo Starlab",
            },
            {
                id: 9,
                href: "https://www.genedirex.com/",
                company: "GeneDirex",
                "text": "Produkty do badań kwasów nukleinowych (PCR, izolacja DNA/RNA, elektroforeza agarozowa), produkty do hodowli komórkowych komórek ssaczych i owadzich oraz odczynniki do transfekcji. Firma posiada w ofercie transiluminator pBLook z diodą led jak i linię produktów do PCR – OnePCR Supermix, są to produkty typu 3 w 1, gdzie poza standardowymi komponentami dodany został barwnik do śledzenia próbki w żelu agarozowym oraz fluorescencyjny barwnik interkalujący do nici DNA. Produkt dostępny z polimerazą Taq, Hot start, Hi-Fi oraz Plus (o wysokiej procesywności).",
                logo: genedirexLogo,
                alt: "Logo GeneDirex",
            },
        ],
    },
    {
        group: "Materialy_ochronne",
        companies: [
            {
                id: 16,
                href: "https://www.starlabgroup.com/",
                company: "Starlab",
                text: "Materiały zużywalne oraz drobny sprzęt laboratoryjny, w tym tipsy serii TipOne® pasujące na pipety takich marek jak Eppendorf®, BRAND®, Capp®, Gilson®, Hamilton®, HTL oraz naczynia hodowlane i inne materiały zużywalne linii produktowej CytoOne®.",
                logo: starlabLogo,
                alt: "Logo Starlab",
            },
        ],
    },
];
export default groups;
